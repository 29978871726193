import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {UrlsTO} from 'api/entities';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {apiVersion} from '../../../api-version.constant';
import {getSuppressErrorToastContext} from '../shared.service';
import {SystemInfoI, TimeZoneTO} from './system.interface';

@Injectable({providedIn: 'root'})
export class SystemEntity {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  getStaticInfo(): Observable<SystemInfoI> {
    return this.http.get<SystemInfoI>(apiVersion + 'system/staticInfo', {context: getSuppressErrorToastContext()});
  }

  getURLs(): Observable<UrlsTO> {
    return this.http.get<UrlsTO>(apiVersion + 'system/urls');
  }

  getEmbedUrl(): Observable<{embedUrl: string; embedUrlSafe: SafeResourceUrl}> {
    return this.getURLs().pipe(
      map(urls => {
        const embedUrl = urls.embed;
        const embedUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
        return {
          embedUrl,
          embedUrlSafe
        };
      })
    );
  }

  getTimezones(): Observable<TimeZoneTO[]> {
    return this.http.get<TimeZoneTO[]>(apiVersion + 'timezones');
  }
}
